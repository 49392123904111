import React, {FC, useEffect, useRef, useState} from 'react';
import {observer} from "mobx-react";
import {thumbAvatar} from "../../../modules/utils";
import {API} from "../../../modules/api";
import { IModel, IPhoto} from "../../../modules/rest";
import Slider from "react-slick";
import {openGalleryModal} from "../../../modals";
import {ReactSVG} from "react-svg";
import expand from "../../../assets/icons/expand.svg";
import AppStore from "../../../store/AppStore";
import {useTranslation} from "react-i18next";
import BlurComponent from "../../../components/BlurComponent";
import {useNavigate} from "react-router-dom";

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

interface Props {
  className?: string;
  canOpenPhoto?: boolean;
  model?: IModel;
}

const ModelProfile: FC<Props> = observer(({
                                            model,
                                            className,
                                            canOpenPhoto = true,
                                          }) => {
  const _timout: any = useRef();
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [activeIdx, setActiveIdx] = useState(0);
  const [swiping, setSwiping] = useState(false);
  const [loading, setLoading] = useState(false);
  const [gallery, setGallery] = useState<IPhoto[]>([]);

  const [nav1, setNav1] = useState<any>(null);
  const [nav2, setNav2] = useState<any>(null);
  let sliderRef1: any = useRef(null);
  let sliderRef2: any = useRef(null);


  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
    return () => {
      clear()
    }
  }, []);

  const clear = () => {
    clearTimeout(_timout?.current)
  }

  useEffect(() => {
    if (model?.id && AppStore.user?.id) {
      getGallery();
    }
  }, [model?.id, AppStore.ready]);

  const getGallery = async () => {
    setLoading(true)
    try {
      const gallery = await API.Models.getMainPhotos(model?.id!, {limit: 50});
      setGallery(gallery);
    } catch (e) {

    } finally {
      setLoading(false);
    }
  }

  const onMouseInOut = (status: boolean) => () => {
    clear();
    if (!gallery?.length) return;
    _timout.current = setTimeout(() => setSwiping(status), status ? 200 : 0);
  }

  const openGallery = (type: 'gallery'|'model', initialIdx?: number) => () => {
    if (!canOpenPhoto || swiping) return;
    let photo;
    if (type === 'gallery' && gallery?.length) {
      photo = gallery.map(item => item.photo);
    } else {
      photo = model?.mainPhotoImage;
    }
    openGalleryModal({image: photo, initialIdx})
  }

  if (!model) return null;

  return (
    <div className={`model-profile ${className || ''}`}>
      <div className="model-profile-gallery">
        <div className='model-profile-bg-img'>
          <img src={thumbAvatar(gallery?.length ? gallery[activeIdx || 0]?.photo.id : model?.mainPhotoImage?.id, 800)} alt="model"/>
        </div>
        {loading || !gallery?.length
          ?
          <BlurComponent
            onPremiumNavigate={() => navigate('/premium/subscription')}
            isNsfw={model?.mainPhotoImage?.isNsfw}
            className='model-profile-slide'
            classNameWrapper='model-profile-slide-wrapper'
          >
            <img src={thumbAvatar(model?.mainPhotoImage?.id, 800)} alt="model"
                 onClick={openGallery('model')}/>
          </BlurComponent>
          :
          <div
            className='btn-expand-container'
            onMouseDown={onMouseInOut(true)}
            onMouseUp={onMouseInOut(false)}
          >
            {canOpenPhoto && <ReactSVG src={expand} className='react-icon btn-expand'/>}
            <Slider
              {...settings}
              beforeChange={(idx, nextSlide) => {
                setSwiping(true)
                setActiveIdx(nextSlide);
                sliderRef2?.current?.slickGoTo(nextSlide);
              }}
              afterChange={(idx) => {
                setSwiping(false);
              }}
              asNavFor={nav2.current}
              ref={sliderRef1}
              className='slider-container  model-profile-slider-main'
              infinite={false}
            >
              {gallery.map((item, idx) => (
                <BlurComponent
                  onPremiumNavigate={() => navigate('/premium/subscription')}
                  isNsfw={item?.photo?.isNsfw}
                  className='model-profile-slide' key={item.id}
                >
                  <img src={thumbAvatar(item.photo.id, 800)} alt="model" onClick={openGallery('gallery', idx)}/>
                </BlurComponent>
              ))}
            </Slider>
            {gallery?.length > 1 &&
            <Slider
              arrows={false}
              asNavFor={nav1.current}
              ref={sliderRef2}
              slidesToShow={6}
              slidesToScroll={6}
              swipeToSlide={true}
              focusOnSelect={true}
              className='slider-container model-profile-slider-small'
              infinite={false}
            >
              {gallery.map((item, idx) => (
                <BlurComponent
                  onContainerClick={() => {
                    sliderRef1?.current?.slickGoTo(idx);
                  }}
                  enableClick={false}
                  isNsfw={item?.photo?.isNsfw}
                  className='model-profile-slide small'
                  key={item.id}
                >
                  <img src={thumbAvatar(item.photo.id, 800)} alt="img" />
                </BlurComponent>
              ))}
            </Slider>
            }
          </div>
        }
      </div>
      {model
        ?
        <div className='model-profile-info'>
          <h4>{model?.name}</h4>
          <p className='text-dark mt-3'>{model?.aboutLocal}</p>
          <hr/>
          <div className='text-bold'>{t('PERSONALITY_ATTRIBUTES')}</div>
          <div className='model-profile-cards'>
            <div className="model-profile-card">
              <div className='text-dark-14 mb-2'>{t('PERSONALITY')}</div>
              <div className='text-semibold-14'>{model?.personalityLocal?.split('(')?.[0]}</div>
            </div>
            <div className="model-profile-card">
              <div className='text-dark-14 mb-2'>{t('OCCUPATION')}</div>
              <div className='text-semibold-14'>{model?.occupationLocal}</div>
            </div>
            <div className="model-profile-card">
              <div className='text-dark-14 mb-2'>{t('HOBBIES')}</div>
              <div className='text-semibold-14'>{model?.hobbiesLocal?.join(', ')}</div>
            </div>
            <div className="model-profile-card">
              <div className='text-dark-14 mb-2'>{t('RELATIONSHIP')}</div>
              <div className='text-semibold-14'>{model?.relationshipLocal}</div>
            </div>
          </div>
          <div className='mt-4 text-bold'>{t('PHYSICAL_ATTRIBUTES')}</div>
          <div className='model-profile-cards'>
            <div className="model-profile-card">
              <div className='text-dark-14 mb-2'>{t('BODY')}</div>
              <div className='text-semibold-14 letter-uppercase'>{t(model?.bodyType?.toUpperCase()!)}</div>
            </div>
            <div className="model-profile-card">
              <div className='text-dark-14 mb-2'>{t('AGE')}</div>
              <div className='text-semibold-14'>{model?.age}</div>
            </div>
            <div className="model-profile-card">
              <div className='text-dark-14 mb-2'>{t('ETHNICITY')}</div>
              <div className='text-semibold-14 letter-uppercase'>{t(model?.ethnicity?.toUpperCase()!)}</div>
            </div>

          </div>
        </div>
        :
        null
      }
    </div>
  );
})

export default ModelProfile;
