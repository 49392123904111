import {useEffect} from 'react';
import {IUser} from "../modules/rest";
import {openPushNotificationModal, openSetUpModal, openWarning18Modal} from "../modals";
import moment from "moment";


const useSetUp = (user?: IUser|null, isTg?: string) => {
  useEffect(() => {
    init();
  }, [user?.id]);

  const init = async () => {
    if (user?.id) {
      if (!user.name || !user.gender) {
        await openSetUpModal();
      } else if (!localStorage.getItem('confirm18+')) {
        await openWarning18Modal();
      }
      const firebaseMessagingRequest = localStorage.getItem('firebaseMessagingRequest');
      if (!firebaseMessagingRequest && !isTg) {
        await openPushNotificationModal();
      }
      localStorage.setItem('firebaseMessagingRequest', moment().format('YYYY-MM-DD HH:mm'));
    }
  }

}

export default useSetUp;