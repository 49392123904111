import React, { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { HeaderSecondary } from '../../../../containers/Header';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ECountry, EPaymentMethodCategory, IGetPaymentMethodsRequest, IPaymentMethod } from '../../../../modules/rest';
import { API } from '../../../../modules/api';
import { toast } from 'react-toastify';
import Button from '../../../../components/Button';
import HorizontalScroll from '../../../../components/HorizontalScroll';
import { Select } from '../../../../components/FormControls';
import { thumb } from '../../../../modules/utils';
import geos from '../../../../translates/en/geos.json';
import world from '../../../../assets/icons/world.svg';
import Loadable, { Spinner } from '../../../../components/Loadable';
import PaymentPlatformIcons from './components/PaymentPlatformIcons';

const filterList = (
  methods?: IPaymentMethod[],
  activeCategory?: IGetPaymentMethodsRequest['category']
): [list: Record<EPaymentMethodCategory, IPaymentMethod[]>, categories: EPaymentMethodCategory[]] => {
  let categories: Record<EPaymentMethodCategory, number> = {
    [EPaymentMethodCategory.Card]: 0,
    [EPaymentMethodCategory.Crypto]: 0,
    [EPaymentMethodCategory.Other]: 0,
  };
  const result: Record<EPaymentMethodCategory, IPaymentMethod[]> = {
    [EPaymentMethodCategory.Card]: [],
    [EPaymentMethodCategory.Crypto]: [],
    [EPaymentMethodCategory.Other]: [],
  };
  methods?.forEach((method) => {
    categories[method.category] = categories[method.category] + 1;
    if (activeCategory && method.category !== activeCategory) return;
    result[method.category].push(method);
  });
  const resCategories: EPaymentMethodCategory[] = [];
  Object.entries(categories).forEach(([c, count]) => {
    if (count) resCategories.push(c as EPaymentMethodCategory);
  });
  return [result, resCategories];
};

interface Props {}

const PaymentMethodPage: FC<Props> = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { paymentType, paymentPlan } = useParams();

  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>();
  const [paymentMethodsList, setPaymentMethodsList] = useState<Record<EPaymentMethodCategory, IPaymentMethod[]>>();
  const [country, setCountry] = useState<ECountry>();
  const [availableCategories, setAvailableCategories] = useState<EPaymentMethodCategory[]>();
  const [activeCategory, setActiveCategory] = useState<IGetPaymentMethodsRequest['category']>();

  const changeCountry = useCallback(
    (country: ECountry) => {
      API.Users.updateProfile({ country }).then(() => navigate(`/premium/${paymentType}`));
    },
    [paymentType]
  );

  useEffect(() => {
    fetchPaymentMethods().then();
  }, []);

  const fetchPaymentMethods = async () => {
    try {
      setLoading(true);
      const res = await API.PaymentMethods.getPaymentMethods({
        price: Number(paymentPlan!),
        country: (country?.split('__')[0] as ECountry) || '',
      });
      setPaymentMethods(res.methods);
      const [methods, categories] = filterList(res.methods, activeCategory);
      setPaymentMethodsList(methods);
      setAvailableCategories(categories);
      //@ts-ignore
      const countryObj = geos[res.country?.toUpperCase()];
      setCountry(`${res.country}__${countryObj?.name.replaceAll(' ', '')}` as ECountry);
    } catch (e: any) {
      toast.error(e);
    } finally {
      setLoading(false);
      setReady(true);
    }
  };

  const handleChangeCategory = (category: IGetPaymentMethodsRequest['category']) => () => {
    setActiveCategory(category);
    setPaymentMethodsList(filterList(paymentMethods, category)[0]);
  };

  const onSubmit = (paymentMethod: IPaymentMethod) => async () => {
    if (paymentMethod.isBillingInfo) {
      return navigate(paymentMethod.id + '', { state: { paymentCategory: paymentMethod.category } });
    }

    if (loading) return;
    setLoading(true);
    try {
      let res;
      //@ts-ignore
      ym(97677527, 'reachGoal', paymentType === 'subscription' ? 'submit3' : 'submit5');
      res = await API.Subscriptions.purchase(paymentPlan!, {
        method: paymentMethod.id,
      });
      window.location.href = res.redirect;
    } catch (e: any) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <HeaderSecondary title="SELECT_COUNTRY" coins onClick={() => navigate(-1)} back />
      {!ready ? (
        <Spinner loading className="align-self-center" />
      ) : (
        <div className="container payment-method-page premium-page">
          <div className="d-flex flex-column align-items-center mb-4">
            <h2 className="page-title mb-3">{t('SELECT_COUNTRY')}</h2>
            <Select
              icon={world}
              className="sm w-auto"
              required
              value={country || ''}
              onChange={(e) => changeCountry(e.target.value.substring(0, 2) as ECountry)}
            >
              {Object.entries(geos).map(([geo, value]) => (
                <option value={`${geo.toLowerCase()}__${value.name.replaceAll(' ', '')}`} key={geo}>
                  {value.name}
                </option>
              ))}
            </Select>
          </div>
          <h2 className="mb-2 text-center">{t('PAYMENT_METHOD')}</h2>
          <div className="d-flex justify-content-center position-relative">
            <HorizontalScroll className="model-style mt-0">
              <Button
                size={'sm'}
                className={!activeCategory ? 'active' : ''}
                btnType="third"
                title="ALL"
                onClick={handleChangeCategory(null)}
              />
              {availableCategories?.map((category) => {
                return (
                  <Button
                    size={'sm'}
                    key={category}
                    className={category === activeCategory ? 'active' : ''}
                    btnType="third"
                    title={category.toUpperCase()}
                    onClick={handleChangeCategory(category)}
                  />
                );
              })}
            </HorizontalScroll>
          </div>
          {!paymentMethods?.length ||
          (activeCategory && !paymentMethodsList?.[activeCategory]?.length) ? null : paymentMethodsList ? (
            <div className="premium-container pt-0 pt-md-2 mt-4">
              <Loadable loading={Boolean(loading && ready)} className="w-100 position-relative z-1">
                {Object.entries(paymentMethodsList).map(([category, list]) => {
                  if (!list?.length) return null;
                  return (
                    <Fragment key={category}>
                      <div className="mt-3 mb-3 text-bold">{t(category.toUpperCase())}</div>
                      <div className="row g-2">
                        {list?.map((method) => (
                          <div className="col-6 col-sm-4" key={method.id}>
                            <div className={`payment-method-item`} onClick={onSubmit(method)}>
                              <div className="payment-method-item-logo">
                                {method.icon ? (
                                  <img src={thumb(method.icon.id, 112)} alt={method.name} />
                                ) : (
                                  <div className="payment-method-item-stub" />
                                )}
                              </div>
                              <div className="text-semibold-14 position-relative z-1 text-center">{method.name}</div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Fragment>
                  );
                })}
              </Loadable>
            </div>
          ) : null}
          <PaymentPlatformIcons />
        </div>
      )}
    </>
  );
});

export default PaymentMethodPage;
