import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {EDictionaryCollection, EFieldGroup, EGender, IUpdateProfileRequest, IUser, IUtm} from "../modules/rest";
import {API} from "../modules/api";
import ChatStore from "./ChatStore";
import HomeStore from "./HomeStore";

class AppStore {
  _ping: any;
  asideVisible: boolean = false;
  ready: boolean = false;
  dict?: Record<EGender, Record<EDictionaryCollection, string[]>>;
  user: IUser | null = null;
  gender: EGender = (localStorage.getItem('gender') as EGender) || EGender.Female;
  clickId = '';

  constructor() {
    const urlParams = new URLSearchParams(window.location.search);
    let queryGender = urlParams.get('gender')?.toLowerCase() as EGender;
    if (queryGender && Object.values(EGender).includes(queryGender)) {
      localStorage.setItem('gender', queryGender);
      this.gender = queryGender;
    }
    const m = window.location.pathname.match(/^\/as\/([0-9A-z\.\-\_=]+)$/);
    if (m) {
      localStorage.setItem('token', m[1]);
    }
    makeAutoObservable(this, {
      ready: observable,
      user: observable,
      gender: observable,
      asideVisible: observable,
      dict: observable,
      clickId: observable,

      init: action,
      fetch: action,
      getUser: action,
      updateUser: action,
      login: action,
      logout: action,
      toggleVisibleAside: action,
      changeGender: action,
      getDict: action,
    });
  }

  init = async (): Promise<any> => {
    clearTimeout(this._ping);
    this.getDict();
    const token = window.localStorage.getItem('token');
    const tgInitData = window?.Telegram?.WebApp?.initData;
    if (tgInitData) {
      const {
        token,
        user
      } = await API.Users.loginWithMainTelegramApp({userData: tgInitData}, [EFieldGroup.UserSubscription, EFieldGroup.UserGender]);
      API.setToken(token);
      runInAction(() => {
        this.user = user;
      });
    } else if (token) {
      API.setToken(token);
      try {
        const user = await this.getUser();
        this.fetch(user);
      } catch (e) {
        this._ping = setTimeout(this.init, 5000);
      }
    }
    runInAction(() => {
      this.ready = true;
    })
    this.getClickId();
  };

  fetch = async (user: IUser) => {
    if (!user) return;
    ChatStore.getList();
    this._ping = setInterval(this.getUser, 60000);
  }

  getClickId = async () => {
    try {
      const utm = localStorage.getItem('utm') || ''
      const clickId = await API.Bots.getClickId({utm: JSON.parse(utm)});
      runInAction(() => {
        this.clickId = clickId;
      })
    } catch (e) {

    } finally {

    }
  }
  getUser = async () => {
    try {
      const user = await API.Users.getMe([EFieldGroup.UserSubscription, EFieldGroup.UserGender]);
      runInAction(() => {
        this.user = user;
      });
      return user;
    } catch (e) {
      throw e;
    }
  }

  updateUser = async (user: IUpdateProfileRequest) => {
    if (!this.user) return;
    try {
      const res = await API.Users.updateProfile(user, [EFieldGroup.UserSubscription, EFieldGroup.UserGender]);
      runInAction(() => {
        this.user = res;
      });
      return res;
    } catch (e) {
      throw e;
    }
  }


  login = (user: IUser) => {
    clearInterval(this._ping);
    runInAction(() => {
      this.user = user;
    });
    this.fetch(user);
    this.getClickId();
  };

  logout = (): void => {
    const tgInitData = window?.Telegram?.WebApp?.initData;
    if (tgInitData) return window?.Telegram?.WebApp?.close();

    API.setToken(null);
    window.localStorage.removeItem('token');
    clearInterval(this._ping);
    runInAction(() => {
      this.user = null;
    });
    ChatStore.clean()
    this.getClickId();
  };

  toggleVisibleAside = (visible?: boolean) => {
    runInAction(() => {
      this.asideVisible = visible ?? !this.asideVisible;
    });
  }

  changeGender = (gender: EGender | 'toggle') => {
    localStorage.removeItem('create-ai-step');
    localStorage.removeItem('create-ai-form');
    if (gender === 'toggle') {
      gender = this.gender === EGender.Male ? EGender.Female : EGender.Male;
    }
    localStorage.setItem('gender', gender);
    runInAction(() => {
      this.gender = gender as EGender;
      HomeStore.tag = undefined;
    });
  }

  getDict = async () => {
    try {
      const res = await API.Dictionary.getFull();
      runInAction(() => {
        this.dict = res;
      });
    } catch (e) {
    }
  }
}

export default new AppStore();