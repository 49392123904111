import React, {FC} from 'react';
import {ECardType} from "../../../modules/rest";
import diners_img from "../assets/images/diners.svg";
import discover_img from "../assets/images/discover.svg";
import jcb_img from "../assets/images/jcb.svg";
import maestro_img from "../assets/images/maestro.svg";
import mastercard_img from "../assets/images/mastercard.svg";
import rupay_img from "../assets/images/rupay.svg";
import unionpay_img from "../assets/images/unionpay.svg";
import visa_img from "../assets/images/visa.svg";
import americanexpress_img from "../assets/images/americanexpress.svg";
import tg_img from "../assets/images/tg.svg";


const images: Record<ECardType | 'tg', any> = {
  [ECardType.Diners]: diners_img,
  [ECardType.Discover]: discover_img,
  [ECardType.JCB]: jcb_img,
  [ECardType.Maestro]: maestro_img,
  [ECardType.MasterCard]: mastercard_img,
  [ECardType.RuPay]: rupay_img,
  [ECardType.UnionPay]: unionpay_img,
  [ECardType.VISA]: visa_img,
  [ECardType.AmericanExpress]: americanexpress_img,
  tg: tg_img,
}

interface Props {
  type?: ECardType | 'tg';
}

const PaymentCardLogo: FC<Props> = ({type}) => {
  if(!type) return null;
  return (
    <div className="list-pay-card-image">
      <img src={images[type]} alt="logo"/>
    </div>
  );
}

export default PaymentCardLogo;