import React, {FC, useEffect, useRef, useState} from 'react';
import {API} from "../../../../../modules/api";
import {EPaymentGateway, ETransactionStatus, ITransaction} from "../../../../../modules/rest";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {useTelegram} from "../../../../../hooks/useTelegram";
import Button from "../../../../../components/Button";
import {PaymentCardLogo} from "../../../../../containers/ListPaymentCard";
import {Spinner} from "../../../../../components/Loadable";
import {ReactSVG} from "react-svg";
import success_svg from "../../../../PaymentStatusPage/assets/icons/success.svg";
import failed_svg from "../../../../PaymentStatusPage/assets/icons/failed.svg";
import {useNavigate} from "react-router-dom";
import AppStore from "../../../../../store/AppStore";

interface Props {
  productId?: number
}

const PaymentTgBtn: FC<Props> = ({productId}) => {
  const {t} = useTranslation();

  const _ping: any = useRef(null);

  const navigate = useNavigate();

  const {tg, tgQueryId} = useTelegram();

  const [loading, setLoading] = useState(false);
  const [paymentMethodId, setPaymentMethodId] = useState<number>();
  const [transaction, setTransaction] = useState<ITransaction>();


  useEffect(() => {
    API.PaymentMethods.getPaymentMethods({gateway: EPaymentGateway.Stars}).then(({methods}) => {
      setPaymentMethodId(methods[0]?.id)
    });
    return () => {
      stopPing();
    }
  }, []);

  useEffect(() => {
    if (transaction?.id) {
      _ping.current = setTimeout(pingStatus, 2000);
    }
  }, [transaction?.id]);


  const stopPing = () => {
    setLoading(false);
    clearTimeout(_ping.current);
  }

  const pingStatus = async () => {
    clearTimeout(_ping.current);
    if (!transaction) return;
    try {
      const res = await API.Transactions.getTransaction(transaction.id!);
      if (res.status === ETransactionStatus.Success || res.status === ETransactionStatus.Failed) {
        stopPing();
        AppStore.getUser();
        _ping.current = setTimeout(() => {
          navigate(`/payment-status?status=${res.status}`);
        }, 2500)
      } else {
        _ping.current = setTimeout(pingStatus, 2000);
      }
    } catch (e: any) {
      toast.error(e);
      stopPing();
    }
  }

  const handlePay = async () => {
    if (loading || !paymentMethodId) return;
    setLoading(true);
    try {
      const res = await API.Subscriptions.purchase(productId!, {method: paymentMethodId});
      setTransaction(res.transaction);
      if (res.transaction.status === ETransactionStatus.Failed) {
        toast.error(t('PAYMENT_FAILED'));
      }
      tg.openTelegramLink(res.redirect);
    } catch (e: any) {
      toast.error(e);
      setLoading(false);
    } finally {

    }
  }

  if (!tgQueryId) return null;

  if (transaction) return (
    <div className={`premium-transaction ${transaction.status}`}>
      <div className='d-flex align-items-center'>
        <PaymentCardLogo type={'tg'}/>
        {/*<div className='text-bold-14 flex-1'>{card.name}</div>*/}
        {loading && <Spinner loading/>}
      </div>
      {(transaction.status === ETransactionStatus.Pending) &&
        <div onClick={() => {
          stopPing();
          setTransaction(undefined);
        }} className='text-tint cursor-pointer text-14 me-2'>{t('CANCEL')}</div>}
      {transaction.status === ETransactionStatus.Success &&
        <ReactSVG src={success_svg} className='react-icon'/>}
      {transaction.status === ETransactionStatus.Failed &&
        <ReactSVG src={failed_svg} className='react-icon'/>}
    </div>
  )

  return (
    <Button
      loading={loading || !paymentMethodId}
      className="modal-footer w-100"
      onClick={handlePay}
      title="PAY_TG"
      style={{maxWidth: 460}}
    />
  );
}

export default PaymentTgBtn;