import React, {FC, useState} from 'react';
import {useTranslation} from "react-i18next";
import logo_small from '../../assets/images/logo_small.png';
import home from '../../assets/icons/home.svg';
import chat from '../../assets/icons/chat.svg';
import gallery from '../../assets/icons/gallery.svg';
import heart from '../../assets/icons/heart.svg';
import diamond from '../../assets/icons/diamond.svg';
import generate from '../../assets/icons/generate.svg';
import public_svg from '../../assets/icons/public.svg';
import logo from '../../assets/images/logo.png';
import {Link, NavLink, useLocation, useNavigate} from "react-router-dom";
import {ReactSVG} from "react-svg";
import magic_wand from "../../assets/icons/magic_wand.svg";
import settings_svg from "../../assets/icons/settings.svg";
import category_svg from "../../assets/icons/category.svg";
import chevron_down_svg from "../../assets/icons/chevron_down.svg";
import Button from "../../components/Button";
import {observer} from "mobx-react";
import AppStore from "../../store/AppStore";
import HomeTimer from "../../pages/HomePage/components/HomeTimer";
import Avatar from "../../components/Avatar";
import useAuth from "../../hooks/useAuth";
import {isSub} from "../../modules/utils";
import Tags from "../Tags";


type TMenu = { path: string, title?: string, icon?: any, needUser?: boolean }

const menus: TMenu[] = [
  {path: '/', title: 'EXPLORE', icon: home, needUser: false},
  {path: '/chat', title: 'CHAT', icon: chat, needUser: true},
  {path: '/gallery', title: 'GALLERY', icon: gallery, needUser: true},
  {path: '/generate-image', title: 'GENERATE_IMAGE', icon: generate, needUser: true},
  {path: '/my-ai', title: 'MY_AI', icon: heart, needUser: false},
  {path: '/public', title: 'PUBLIC_CHATS', icon: public_svg, needUser: false},
]

interface Props {
}

const Sider: FC<Props> = observer(() => {
  const {t} = useTranslation();
  const [onAuthClick] = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [categoriesVisible, setCategoriesVisible] = useState(false);
  const [smallSider, setSmallSider] = useState(!Boolean(localStorage.getItem('smallSider')));

  const toggleCategoriesVisible = (e: any) => {
    e.preventDefault();
    setSmallSider(true);
    setCategoriesVisible(prevState => !prevState);
  }
  const onToggleSize = () => {
    setCategoriesVisible(false);
    setSmallSider(prevState => {
      prevState ? localStorage.setItem('smallSider', '1') : localStorage.removeItem('smallSider')
      return !prevState
    })
  }

  const onNavClick = (menu: TMenu) => () => {
    AppStore.toggleVisibleAside();
    if (!AppStore.user?.id && menu.needUser) {
      return onAuthClick("login", () => {
        navigate(menu.path);
      }, {prevPathname: menu.path});
    }
    navigate(menu.path);
  }

  const addActiveClass = (path: string): string => {
    if (path === '/' && location.pathname === '/') return ' active';
    if (location.pathname.includes(path.slice(1)) && path !== '/') return ' active';
    return '';
  }

  return (
    <aside className={AppStore.asideVisible ? ' visible' : ''} onClick={() => AppStore.toggleVisibleAside()}>
      <div className={`aside ${smallSider ? 'big-aside' : 'small-aside'}`} onClick={(e) => e.stopPropagation()}>
        <div className='aside-top'>
          <Link to='/' className='aside-logo' onClick={() => AppStore.toggleVisibleAside()}>
            <img src={logo_small} alt="logo" className='aside-logo-small'/>
            <img src={logo} alt="logo" className='aside-logo-big'/>
          </Link>
          <div className='aside-list-container'>
            <div className='aside-list'>
              {AppStore.user?.id
                ?
                <Link to='/settings' className='aside-user' onClick={() => AppStore.toggleVisibleAside()}>
                  <Avatar user size='sm'/>
                  <div className='text-bold-14 ms-2 text-truncate pe-2 flex-1'>{AppStore.user?.name}</div>
                  <ReactSVG src={settings_svg} className='react-icon'/>
                </Link>
                :
                null
              }
              <nav>
                <ul>
                  {menus.map((menu) => (
                    <li key={menu.path}>
                      <div className={`menu-item${addActiveClass(menu.path)}`} onClick={onNavClick(menu)}>
                        <ReactSVG src={menu.icon} className='react-icon'/>
                        <span className='menu-item-title'>{t(menu.title!)}</span>
                      </div>
                    </li>
                  ))}
                  <li>
                    <NavLink to={'/tags'} className={`menu-item menu-tags${categoriesVisible ? ' visible' : ''}`}
                          onClick={toggleCategoriesVisible}>
                      <div className='menu-tags-btn'>
                        <ReactSVG src={category_svg} className='react-icon'/>
                        <span className='text-truncate menu-item-title'>{t('CATEGORIES')}</span>
                        <ReactSVG src={chevron_down_svg} className='menu-tags-arrow react-icon'/>
                      </div>
                      <div onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}>
                        <Tags onChange={() => {
                          AppStore.toggleVisibleAside()
                          navigate('/tags')
                        }}  />
                      </div>
                    </NavLink>
                  </li>
                </ul>
              </nav>
              <div className='aside-btns'>
                <Button
                  onClick={() => AppStore.toggleVisibleAside()}
                  href={'/create-ai'}
                  className='w-100'
                  btnType='secondary'
                  icon={magic_wand}
                >
                  <span className='menu-item-title'>{t('CREATE_CHARACTER')}</span>
                </Button>
                {(AppStore.ready && !isSub(AppStore.user?.subscription?.status)) &&
                  <Button
                    onClick={onNavClick({path: '/premium/subscription', needUser: true})}
                    className='w-100 mt-2'
                    icon={diamond}
                  >
                    <span className='menu-item-title'>{t('BECOME_PREMIUM')}</span>
                  </Button>
                }
              </div>
            </div>
          </div>
        </div>
        {(AppStore.ready && !isSub(AppStore.user?.subscription?.status)) &&
        <div className="aside-footer">
          <div className='d-flex align-items-center justify-content-between mb-2'>
            <div className='text-14 me-2'>{t('FIRST_SUB')}</div>
            <HomeTimer size={'sm'}/>
          </div>
          <Button
            className='w-100'
            iconAbsolute
            onClick={onNavClick({path: '/premium/subscription', needUser: true})}
            icon={diamond}
            btnType='secondary'
            size='sm'
            title='UP_OFF_75'
          />
        </div>
        }
        <div className='aside-toggle' onClick={onToggleSize}>
          <div className="aside-toggle-icon"/>
        </div>
      </div>
    </aside>
  );
})

export default Sider;